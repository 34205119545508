<template>
  <div>
    <div class="text-center">
      <h1>
        Presse
      </h1>
      <p>
        <a
          href="https://legacy.data.gouv.fr/fr/datasets/aides-a-la-presse-classement-des-titres-de-presse-aides/"
        >
          Aides à la presse : classement des titres de presse aidés
        </a>
      </p>
    </div>
    <div class="px-4">
      <v-switch
        color="red"
        v-model="onlyWithPlur"
        label="Seulement avec aides au pluralisme"
      />
      <div v-if="year" class="d-flex ">
        <div v-for="(y, yI) in years" :key="yI" class="mr-2">
          <v-checkbox
            v-model="selectedYears"
            :value="y"
            :label="`${y}`"
          ></v-checkbox>
        </div>
      </div>
    </div>
    <div v-if="sum" class="pa-4">
      Total:

      {{
        new Intl.NumberFormat("fr", {
          style: "currency",
          currency: "EUR",
        }).format(sum)
      }}
      pour

      {{ nodes.length }} titres en {{ selectedYears }}
    </div>
    <div v-if="nodes && min && max && sum">
      <v-virtual-scroll :bench="1" :items="nodes" height="500" item-height="84">
        <template v-slot:default="{ item, itemI }">
          <v-list-item :key="itemI">
            <div style="width:100%">
              <div>
                <b> {{ item.name }} ({{ item.year }}) </b>
              </div>
              <v-progress-linear
                color="blue"
                height="20"
                :value="(item.cash / sum) * 100"
              >
                <div style="color:white;">
                  {{ ((item.cash / sum) * 100).toFixed(2) }}% des aides versees
                  aux journaux
                </div>
              </v-progress-linear>
              <v-progress-linear height="20" :value="(item.cash / max) * 100">
                <div style="color:white;">
                  {{
                    new Intl.NumberFormat("fr", {
                      style: "currency",
                      currency: "EUR",
                    }).format(item.cash)
                  }}
                </div>
              </v-progress-linear>
              <!-- <v-progress-linear
              
                height="20"
                :value="(((item.plur / item.cash) * item.cash) / max) * 100"
              >
                <div style="color:white;">
                  dont
                  {{ ((item.plur / item.cash) * 100).toFixed(2) }}% d'aides au
                  pluralisme soit
                  {{
                    new Intl.NumberFormat("fr", {
                      style: "currency",
                      currency: "EUR",
                    }).format(item.plur)
                  }}
                </div>
              </v-progress-linear> -->
              <div class="d-flex">
                <v-progress-linear
                  :style="`width:${(item.cash / max) * 100}%`"
                  height="20"
                  :value="(item.plur / item.cash) * 100"
                  color="red"
                >
                  <div
                    style="color:white;"
                    :title="
                      `dont ${((item.plur / item.cash) * 100).toFixed(
                        2
                      )}% d'aides au pluralisme ${new Intl.NumberFormat('fr', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(item.plur)}`
                    "
                  >
                    {{ ((item.plur / item.cash) * 100).toFixed(2) }}
                  </div>
                </v-progress-linear>
                <div v-if="(item.plur / item.cash) * 100 > 0">
                  {{
                    `dont ${((item.plur / item.cash) * 100).toFixed(
                      2
                    )}% d'aides au pluralisme ${new Intl.NumberFormat("fr", {
                      style: "currency",
                      currency: "EUR",
                    }).format(item.plur)}`
                  }}
                </div>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </div>
    <div class="svgContainer" v-if="false">
      <svg
        v-if="false"
        id="map"
        :viewBox="`0 0 ${width} ${height}`"
        preserveAspectRatio="xMinYMin meet"
        cursor="grab"
      >
        <g id="nodesAndLinks">
          <g class="links">
            <g v-for="(link, linkI) in links" :key="linkI">
              <line
                :x1="link.source.x"
                :y1="link.source.y"
                :x2="link.target.x"
                :y2="link.target.y"
                :stroke="
                  link.source.name === focusedNode ||
                  link.target.name === focusedNode
                    ? 'gold'
                    : 'grey'
                "
                :stroke-width="
                  link.source.name === focusedNode ||
                  link.target.name === focusedNode
                    ? 4
                    : 1
                "
                opacity="0.5"
                cursor="pointer"
              ></line>
            </g>
          </g>
          <g class="nodes" v-if="nodes">
            <g v-for="(node, nodeI) in nodes" :key="nodeI">
              <circle
                @mouseover="focusedNode = node.name"
                :cx="node.x"
                :cy="node.y"
                :r="node.cash / 100000"
                :fill="circleFill()"
                cursor="pointer"
                opacity="0.5"
                stroke="black"
                stroke-width="1"
              ></circle>
              <text
                :x="node.x"
                :y="node.y"
                :font-size="(Math.sqrt(textsize) * node.cash) / 100000"
                :fill="node.name === focusedNode ? 'blue' : 'black'"
                :stroke="node.name === focusedNode ? 'gold' : 'white'"
                stroke-width="2"
                stroke-opacity="0.3"
                cursor="pointer"
              >
                {{ node.name }}
              </text>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import _ from "lodash";

import * as d3 from "d3";
import presseJson from "@/data/presse";

export default {
  name: "Presse",
  data() {
    return {
      selectedYears: [],
      years: [],
      year: "",
      onlyWithPlur: false,
      presseJson: presseJson,
      nodes: [],
      min: null,
      max: null,
      sum: null,
      hideCountries: true,
      focusedNode: "",
      g: null,
      svg: null,
      ratio: 1.59,
      textsize: 100,
      strength: -165,
      forceCollideFactor: 29,
      width: 2450,
      factbook: null,
      simulation: null,
      mapLoading: true,
    };
  },
  watch: {
    onlyWithPlur: function() {
      this.createNodes();
      this.computeMinMax();
      // this.setAllyears();
    },
    selectedYears: function() {
      this.createNodes();
      this.computeMinMax();
      // this.setAllyears();
    },
    links: function() {
      var i = 0;
      while (i < 10) {
        this.createSimulation();
        this.simulation.tick(1000);
        i++;
      }
      this.mapLoading = false;
    },
    width: function() {
      this.createSimulation();
    },
    ratio: function() {
      this.createSimulation();
    },
    strength: function() {
      this.createSimulation();
    },
    forceCollideFactor: function() {
      this.createSimulation();
    },
  },
  mounted() {
    this.svg = d3.select("#map");
    this.g = d3.select("#nodesAndLinks");
    const zoom = d3
      .zoom()
      .scaleExtent([0.1, 10])
      .on("zoom", this.zoomed);
    this.svg.call(zoom);
    this.setAllyears();
    this.createNodes();
    this.createSimulation();
    this.computeMinMax();
    this.simulation.tick(100);
  },
  methods: {
    setAllyears() {
      this.years = [];
      this.presseJson.forEach((n) => {
        if (!this.years.includes(n.fields.annee)) {
          this.years.push(n.fields.annee);
        }
      });
      this.year = this.years.sort((a, b) => b - a)[0];
      this.selectedYears.push(this.year);
    },
    computeMinMax() {
      this.max = _.maxBy(this.nodes, "cash")?.cash;
      this.min = _.minBy(this.nodes, "cash")?.cash;
      this.sum = _.sumBy(this.nodes, "cash");
    },
    createNodes() {
      var nodes = this.presseJson
        .slice()
        .map((p) => {
          return {
            name: p.fields.beneficiaires,
            year: p.fields.annee,
            cash: p.fields["total_des_aides_individuelles_1_2_3_en_eur"],
            plur:
              p.fields["dont_aides_au_pluralisme_aides_directes_en_eur"] || 0,
          };
        })
        .slice()
        .filter((n) => this.selectedYears.includes(n.year))
        .slice()
        .sort((a, b) => b.cash - a.cash);
      if (this.onlyWithPlur) {
        nodes = nodes.slice().filter((n) => {
          return n.plur > 0;
        });
      }
      this.nodes = nodes;
    },
    tick() {
      this.createSimulation();
    },
    circleFill() {
      var circleFill = "grey";

      return circleFill;
    },
    zoomed(event) {
      const { transform } = event;
      this.g.attr("transform", transform);
      this.g.attr("stroke-width", 1 / transform.k);
    },
    createSimulation() {
      this.simulation = d3
        .forceSimulation(this.nodes)
        .force("charge", d3.forceManyBody().strength(this.strength))
        .force(
          "link",
          d3.forceLink(this.links).id((d) => d.name)
        )
        .force("x", d3.forceX())
        .force("y", d3.forceY())
        // .force(
        //   "collide",
        //   d3.forceCollide((d) =>
        //     Math.sqrt(d.targets.length * this.forceCollideFactor)
        //   )
        // )
        .force("center", d3.forceCenter(this.centerX, this.centerY));
      this.mapLoading = false;
    },
  },
  computed: {
    height() {
      return this.width / this.ratio;
    },
    centerX() {
      return this.width / 2;
    },
    centerY() {
      return this.height / 2;
    },
    yellow() {
      return d3.interpolateYlGn(0);
    },
    links() {
      var links = [];
      // this.nodes.forEach((node) => {
      //   node.targets.forEach((target) => {
      //     if (!this.hideCountries) {
      //       links.push({
      //         source: node,
      //         target: this.nodes.find((n) => n.name === target),
      //       });
      //     }
      //     if (
      //       this.hideCountries &&
      //       !this.countries.includes(target) &&
      //       !this.countries.includes(node.name)
      //     ) {
      //       links.push({
      //         source: node,
      //         target: this.nodes.find((n) => n.name === target),
      //       });
      //     }
      //   });
      // });
      return links;
    },
  },
};
</script>

<style scoped>
.svgContainer {
  border: 1vw solid black;
}
</style>
