var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"px-4"},[_c('v-switch',{attrs:{"color":"red","label":"Seulement avec aides au pluralisme"},model:{value:(_vm.onlyWithPlur),callback:function ($$v) {_vm.onlyWithPlur=$$v},expression:"onlyWithPlur"}}),(_vm.year)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.years),function(y,yI){return _c('div',{key:yI,staticClass:"mr-2"},[_c('v-checkbox',{attrs:{"value":y,"label":`${y}`},model:{value:(_vm.selectedYears),callback:function ($$v) {_vm.selectedYears=$$v},expression:"selectedYears"}})],1)}),0):_vm._e()],1),(_vm.sum)?_c('div',{staticClass:"pa-4"},[_vm._v(" Total: "+_vm._s(new Intl.NumberFormat("fr", { style: "currency", currency: "EUR", }).format(_vm.sum))+" pour "+_vm._s(_vm.nodes.length)+" titres en "+_vm._s(_vm.selectedYears)+" ")]):_vm._e(),(_vm.nodes && _vm.min && _vm.max && _vm.sum)?_c('div',[_c('v-virtual-scroll',{attrs:{"bench":1,"items":_vm.nodes,"height":"500","item-height":"84"},scopedSlots:_vm._u([{key:"default",fn:function({ item, itemI }){return [_c('v-list-item',{key:itemI},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',[_c('b',[_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.year)+") ")])]),_c('v-progress-linear',{attrs:{"color":"blue","height":"20","value":(item.cash / _vm.sum) * 100}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(((item.cash / _vm.sum) * 100).toFixed(2))+"% des aides versees aux journaux ")])]),_c('v-progress-linear',{attrs:{"height":"20","value":(item.cash / _vm.max) * 100}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(new Intl.NumberFormat("fr", { style: "currency", currency: "EUR", }).format(item.cash))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('v-progress-linear',{style:(`width:${(item.cash / _vm.max) * 100}%`),attrs:{"height":"20","value":(item.plur / item.cash) * 100,"color":"red"}},[_c('div',{staticStyle:{"color":"white"},attrs:{"title":`dont ${((item.plur / item.cash) * 100).toFixed(
                      2
                    )}% d'aides au pluralisme ${new Intl.NumberFormat('fr', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(item.plur)}`}},[_vm._v(" "+_vm._s(((item.plur / item.cash) * 100).toFixed(2))+" ")])]),((item.plur / item.cash) * 100 > 0)?_c('div',[_vm._v(" "+_vm._s(`dont ${((item.plur / item.cash) * 100).toFixed( 2 )}% d'aides au pluralisme ${new Intl.NumberFormat("fr", { style: "currency", currency: "EUR", }).format(item.plur)}`)+" ")]):_vm._e()],1)],1)])]}}],null,false,3422862240)})],1):_vm._e(),(false)?_c('div',{staticClass:"svgContainer"},[(false)?_c('svg',{attrs:{"id":"map","viewBox":`0 0 ${_vm.width} ${_vm.height}`,"preserveAspectRatio":"xMinYMin meet","cursor":"grab"}},[_c('g',{attrs:{"id":"nodesAndLinks"}},[_c('g',{staticClass:"links"},_vm._l((_vm.links),function(link,linkI){return _c('g',{key:linkI},[_c('line',{attrs:{"x1":link.source.x,"y1":link.source.y,"x2":link.target.x,"y2":link.target.y,"stroke":link.source.name === _vm.focusedNode ||
                link.target.name === _vm.focusedNode
                  ? 'gold'
                  : 'grey',"stroke-width":link.source.name === _vm.focusedNode ||
                link.target.name === _vm.focusedNode
                  ? 4
                  : 1,"opacity":"0.5","cursor":"pointer"}})])}),0),(_vm.nodes)?_c('g',{staticClass:"nodes"},_vm._l((_vm.nodes),function(node,nodeI){return _c('g',{key:nodeI},[_c('circle',{attrs:{"cx":node.x,"cy":node.y,"r":node.cash / 100000,"fill":_vm.circleFill(),"cursor":"pointer","opacity":"0.5","stroke":"black","stroke-width":"1"},on:{"mouseover":function($event){_vm.focusedNode = node.name}}}),_c('text',{attrs:{"x":node.x,"y":node.y,"font-size":(Math.sqrt(_vm.textsize) * node.cash) / 100000,"fill":node.name === _vm.focusedNode ? 'blue' : 'black',"stroke":node.name === _vm.focusedNode ? 'gold' : 'white',"stroke-width":"2","stroke-opacity":"0.3","cursor":"pointer"}},[_vm._v(" "+_vm._s(node.name)+" ")])])}),0):_vm._e()])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h1',[_vm._v(" Presse ")]),_c('p',[_c('a',{attrs:{"href":"https://legacy.data.gouv.fr/fr/datasets/aides-a-la-presse-classement-des-titres-de-presse-aides/"}},[_vm._v(" Aides à la presse : classement des titres de presse aidés ")])])])
}]

export { render, staticRenderFns }